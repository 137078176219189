import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { FormattedMessage } from "react-intl";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="text-center py-5">
    <h1>
      <FormattedMessage defaultMessage="404: Not Found" />
    </h1>
    <p>
      <FormattedMessage defaultMessage="You just hit a route that doesn't exist... the sadness." />
    </p>
    </div>
  </Layout>
)

export default NotFoundPage;
